import { createModule } from "saga-slice";
import { call, put } from "redux-saga/effects";
import { saveStore, updateStore } from "services/storeService";

const storeSlice = createModule({
    name: "store",
    initialState: {
        isFetching: false,
        error: null
    },
    reducers: {
        create: (state) => {
            state.isFetching = true;
        },
        find: (state) => {
            state.isFetching = true;
        },
        update: (state) => {
            state.isFetching = true;
        },
        findByUsername: (state) => {
            state.isFetching = true;
        },
        finishFetching: (state) => {
            state.isFetching = false;
        },
        fetchError: (state) => {
            state.isFetching = false;
            state.error = "An error occured";
        },
    },
    sagas: (A) => ({
        *[A.update]({ payload }) {
            try {
                const { data } = yield updateStore(payload.data);
                yield put(A.finishFetching());
                yield call(payload.onSuccess, data);
            } catch (e) {
                console.log(e);
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },
        
        *[A.create]({payload}) {
            try {
                const { data } =  yield saveStore(payload.data);
                yield put(A.finishFetching());
                yield call(payload.onSuccess, data?.body);
            } catch (e) {
                console.log(e);
                yield put(A.finishFetching());
                yield put(A.fetchError());
                yield call(payload.onError);
            }
        },
        // *[A.find]({payload}) {
        //     try {
        //         const {data} = yield find(payload.id);
        //         yield put(A.finishFetching());
        //         yield call(payload.onSuccess, data.body);
        //     } catch (e) {
        //         console.log(e);
        //         yield put(A.finishFetching());
        //         yield put(A.fetchError());
        //     }
        // },
        // *[A.findByUsername]({payload}) {
        //     try {
        //         const {data} = yield findByUsername(payload.username);
        //         yield put(A.finishFetching());
        //         yield call(payload.onSuccess, data.body);
        //     } catch (e) {
        //         console.log(e);
        //         yield put(A.finishFetching());
        //         yield put(A.fetchError());
        //     }
        // },
        
    })
})

export default storeSlice;
export const storeActions = storeSlice.actions;
