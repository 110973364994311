import { call, put } from "redux-saga/effects";
import { createModule } from "saga-slice";
import { deleteImage, downloadTemp, findProductByBarcode, findProductByCategory, findProductById, findProductBySubCategory, listStockEntreByProduct, loadProducts, saveEntry, saveProduct, search, setPrincipleImage, updateEntry, updateProduct, uploadData, uploadImageProduct } from "services/productService";

const productSlice = createModule({
    name: "product",
    initialState: {
        products: [],
        stocks: [],
        current: null,
        isFetching: false,
        error: null,
        total: 0,
    },
    reducers: {
        downloadTemp: (state) => {
            state.isFetching = true;
        },
        fetchProducts: (state) => {
            state.isFetching = true;
        },
        findByBarcode: (state) => {
            state.isFetching = true;
        },
        search: (state) => {
            state.isFetching = true;
        },
        listStockEntreByProduct: (state) => {
            state.isFetching = true;
        },
        finishFetching: (state) => {
            state.isFetching = false;
        },
        fetchSuccess: (state, payload) => {
            state.products = payload.body.content;
            state.total = payload.body.totalElements;
        },
        fetchStockSuccess: (state, payload) => {
            state.stocks = payload.body.content;
            state.total = payload.body.totalElements;
        },
        findByIdSuccess: (state, payload) => {
            state.current = payload;
        },
        fetchError: (state) => {
            state.error = "An error occured";
        },
        findById: (state) => {
            state.isFetching = true;
        },
        findByCategory: (state) => {
            state.isFetching = true;
        },
        findBySubCategory: (state) => {
            state.isFetching = true;
        },
        addProduct: (state) => {
            state.isFetching = true;
        },
        addStockEntry: (state) => {
            state.isFetching = true;
        },
        editStockEntry: (state) => {
            state.isFetching = true;
        },
        deleteProduct: (state) => {
            state.isFetching = true;
        },
        editProduct: (state) => {
            state.isFetching = true;
        },
        upload: (state) => {
            state.isFetching = true;
        },
        choosePrinciple: (state) => {
            state.isFetching = true;
        },
        removeImage: (state) => {
            state.isFetching = true;
        },
        uploadFile: (state) => {
            state.isFetching = true;
        },
        uploadData: (state) => {
            state.isFetching = true;
        },
    },

    sagas: (A) => ({
        *[A.search]({ payload }) {
            try {
                const { data } = yield search(payload);
                console.log(data);
                yield put(A.finishFetching());
                yield put(A.fetchSuccess(data));
            } catch (e) {
                console.log(e);
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },

        *[A.downloadTemp]({ payload }) {
            try {
                const { data } = yield downloadTemp(payload.data);
                yield put(A.finishFetching());
                yield call(payload.onSuccess, data);
            } catch (e) {
                console.log(e);
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },
        *[A.uploadData]({payload}) {
            try {
                yield uploadData(payload.formData);
                yield put(A.finishFetching());
                yield call(payload.onSuccess);
            } catch (e) {
                console.log(e);
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },
        *[A.listStockEntreByProduct]({ payload }) {
            try {
                const { data } = yield listStockEntreByProduct(payload.productId);
                yield put(A.finishFetching());
                yield call(payload.onSuccess, data?.body);
            } catch (e) {
                console.log(e);
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },
        *[A.fetchProducts]({ payload }) {
            try {
                console.log(payload)
                const { data } = yield loadProducts(payload.page, payload.size);
                yield put(A.finishFetching());
                yield put(A.fetchSuccess(data));
            } catch (e) {
                console.log(e)
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },
        *[A.findByCategory]({ payload }) {
            try {
                const { data } = yield findProductByCategory(payload.id, payload.page, payload.size);
                yield put(A.finishFetching());
                yield put(A.fetchSuccess(data));
            } catch (e) {
                console.log(e)
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },
        *[A.findBySubCategory]({ payload }) {
            try {
                const { data } = yield findProductBySubCategory(payload.id, payload.page, payload.size);
                yield put(A.finishFetching());
                yield put(A.fetchSuccess(data));
            } catch (e) {
                console.log(e)
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },
        *[A.findById]({ payload }) {
            try {
                console.log(payload)
                const { data } = yield findProductById(payload);
                console.log(data)
                yield put(A.finishFetching());
                yield put(A.findByIdSuccess(data));
            } catch (e) {
                console.log(e)
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },
        *[A.findByBarcode]({ payload }) {
            try {
                console.log(payload)
                const { data } = yield findProductByBarcode(payload.barcode);
                yield put(A.finishFetching());
                yield call(payload.onSuccess, data?.body);
            } catch (e) {
                console.log(e)
                yield put(A.finishFetching());
                yield put(A.fetchError());
                yield call(payload.onError, e);
            }
        },
        *[A.addProduct]({ payload }) {
            try {
                console.log("addCategory payload", payload);
                const { data } = yield saveProduct(payload.data);
                yield call(payload.onSuccess, data);
            } catch (e) {
                console.log(e)
                yield put(A.finishFetching());
                yield put(A.fetchError());
                yield call(payload.onError, e);
            }
        },
        *[A.addStockEntry]({ payload }) {
            try {
                const { data } = yield saveEntry(payload.data);
                yield call(payload.onSuccess, data);
            } catch (e) {
                console.log(e)
                yield put(A.finishFetching());
                yield put(A.fetchError());
                yield call(payload.onError, e);
            }
        },
        *[A.editStockEntry]({ payload }) {
            try {
                const { data } = yield updateEntry(payload.data);
                yield put(A.fetchProducts());
                yield call(payload.onSuccess, data);
            } catch (e) {
                yield put(A.finishFetching());
                yield put(A.fetchError());
                yield call(payload.onError);
            }
        },
        *[A.deleteProduct]({ payload }) {
            try {
                console.log("deleteProduct payload", payload);
            } catch (e) {
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },
        *[A.editProduct]({ payload }) {
            try {
                const { data } = yield updateProduct(payload.data);
                yield put(A.fetchProducts());
                yield call(payload.onSuccess, data);
            } catch (e) {
                yield put(A.finishFetching());
                yield put(A.fetchError());
                yield call(payload.onError, e);
            }
        },
        *[A.uploadFile]({ payload }) {
            try {
                const { data } = yield uploadImageProduct(payload.formData);
                yield put(A.finishFetching());
                yield call(payload.onSuccess, data?.body);
            } catch (e) {
                console.log(e);
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },
        *[A.choosePrinciple]({ payload }) {
            try {
                console.log(payload)
                yield setPrincipleImage(payload.id);
                yield call(payload.onSuccess);
            } catch (e) {
                yield put(A.finishFetching());
                yield put(A.fetchError());
                yield call(payload.onError, e);
            }
        },
        *[A.removeImage]({ payload }) {
            try {
                console.log(payload)
                yield deleteImage(payload.id);
                yield call(payload.onSuccess);
            } catch (e) {
                yield put(A.finishFetching());
                yield put(A.fetchError());
                yield call(payload.onError, e);
            }
        }
    })
})

export default productSlice;
export const productActions = productSlice.actions;

