import { Navigate } from 'react-router';
import { LOGIN_PAGE, REGISTER_PAGE, CATEGORY_PAGE, DASHBOARD_PAGE, PRODUCT_PAGE, LOGOUT_PAGE, SALES_PAGE, ORDER_EDIT, ORDER_NEW } from './constants';
import Logout from 'views/pages/authentication/auth-forms/Logout';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import SaleContainer from 'views/sales/SaleContainer';
import SaleList from 'views/sales/SaleList';
// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const CategoryContainer = Loadable(lazy(() => import('views/database/category/CategoryContainer')));
const ProductContainer = Loadable(lazy(() => import('views/database/product/ProductContainer')));
// ==============================|| ROUTING RENDER ||============================== //
const publicRoutes = [
	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: <Navigate to="/login" /> },
	{ path: LOGOUT_PAGE, component: <Logout /> },
	{ path: LOGIN_PAGE, component: <AuthLogin3 /> },
    { path: REGISTER_PAGE, component: <AuthRegister3 /> }
];

const authProtectedRoutes = [
	{ path: DASHBOARD_PAGE, component: <DashboardDefault />, roles:["ROLE_ADMIN"]},
	{ path: CATEGORY_PAGE, component: <CategoryContainer /> , roles:["ROLE_ADMIN","ROLE_AGENT"]},
	{ path: PRODUCT_PAGE, component: < ProductContainer/> , roles:["ROLE_ADMIN","ROLE_AGENT"]},
	{ path: SALES_PAGE, component: < SaleList/> , roles:["ROLE_ADMIN","ROLE_AGENT"]},
	{ path: ORDER_EDIT, component: < SaleContainer/> , roles:["ROLE_ADMIN","ROLE_AGENT"]},
	{ path: ORDER_NEW, component: < SaleContainer/> , roles:["ROLE_ADMIN","ROLE_AGENT"]},

];

export { authProtectedRoutes, publicRoutes };

