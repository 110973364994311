import axios from "axios";
import config from "../config";
import instance from "utils/AxiosInterceptor";

export const createOrder = (request) => {
  return instance.post(`/sale/create`, JSON.stringify(request));
}

export const updateOrder = (updated) => {
  return instance.put(`/sale/update`, JSON.stringify(updated));
}

export const loadOrders = (page, size) => {
  return instance.get(`/sale/list?page=${page}&size=${size}`);
}

export const getOrderNumber = () => {
  return instance.get(`/sale/getOrderNumber`);
}

export const find = (id) => {
  return instance.get(`/sale/${id}`);
};

export const printReceipt = async (id) => {
    return await instance.get(`/sale/bill/${id}`);
};

export const printReceiptIntern = async (id) => {
  return await instance.get(`/sale/bill/intern/${id}`);
};


export const search = (query) => {
  return instance.post(`/sale/search`, JSON.stringify(query));
};

export const report = (query) => {
  return instance.post(`/sale/report`, JSON.stringify(query));
};
