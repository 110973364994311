

// ==============================|| MINIMAL LAYOUT ||============================== //

import React from "react";
import { Component } from "react";
import withRouter from "utils/withRouter";

class MinimalLayout extends Component {
    constructor(props) {
        super(props);
        this.state={};
        this.capitalizeFirstLetter.bind(this);
    }
    
    capitalizeFirstLetter = string => {
        return string.charAt(1).toUpperCase() + string.slice(2);
      };

      componentDidMount(){
        let currentage = this.capitalizeFirstLetter(this.props.router.location.pathname);
        currentage = currentage.replaceAll("-" , " ");

        document.title =
          currentage + " | LUXSALE";
    }
    render() {
        return <React.Fragment>
            {this.props.children}
        </React.Fragment>;
    }
}

export default (withRouter(MinimalLayout));
