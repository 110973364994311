// assets
import { IconCoin, IconCash, IconShadow, IconWindmill } from '@tabler/icons';

// constant
const icons = {
    IconCoin,
    IconCash,
    IconShadow,
    IconWindmill
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const sales = {
    id: 'sales',
    title: 'Sales',
    type: 'group',
    children: [
        {
            id: 'newsale',
            title: 'New Sale',
            type: 'item',
            url: '/sales/new',
            icon: icons.IconCoin,
            breadcrumbs: false
        },
        {
            id: 'listsale',
            title: 'All Sales',
            type: 'item',
            url: '/sales/all',
            icon: icons.IconCash,
            breadcrumbs: false
        }
    ]
};

export default sales;
