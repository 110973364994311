import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';
import database from './database';
import sales from './sale';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, database, sales, other]
};

export default menuItems;
