import axios from "axios";
import config from "../config";

export const loadData = () => {
    return axios.get(`${config.baseURL}/data`, {
        headers: {
          "Content-type": "application/json"
        },
      });
};

  