import { Button, Grid, Paper, TextField } from "@mui/material";
import useScriptRef from "hooks/useScriptRef";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";


const classes = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: 300,
    margin: 100,
  },
  //style for font size
  resize: {
    fontSize: 50
  },
}

export default function Calculator({ line, onUpdate, onDelete }) {
  console.log(line)
  const[value, setValue] = useState('');

  useEffect(() => {
    if(line){
      setValue(line.qte);
    }
  }, [line]);

  const handleUpdate = (qte) => {
    if(line){
      setValue(qte);
      onUpdate(line, qte);
    }
    
  }


  return (
    <React.Fragment>
      <Paper sx={{backgroundColor: "#e0e0e0"}}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField   InputProps={{style: {fontSize: 24}}} // font size of input text
              fullWidth 
              type="number"
              onChange={(event) => handleUpdate(event.target.value)}
              value={`${value}`}>
                
              </TextField>
          </Grid>
          <Grid item xs={3}>
            <Button fullWidth variant="contained" color="info" onClick={() => handleUpdate(1)}>1</Button>
          </Grid>
          <Grid item xs={3}>
            <Button fullWidth variant="contained" color="info" onClick={() => handleUpdate(2)}>2</Button>
          </Grid>
          <Grid item xs={3}>
            <Button fullWidth variant="contained" color="info" onClick={() => handleUpdate(3)}>3</Button>
          </Grid>
          
          <Grid item xs={3}>
            <Button fullWidth variant="contained" color="info" onClick={() => handleUpdate(4)}>4</Button>
          </Grid>
          <Grid item xs={3}>
            <Button fullWidth variant="contained" color="info" onClick={() => handleUpdate(5)}>5</Button>
          </Grid>
          <Grid item xs={3}>
            <Button fullWidth variant="contained" color="info" onClick={() => handleUpdate(6)}>6</Button>
          </Grid>
          
          <Grid item xs={3}>
            <Button fullWidth variant="contained" color="info" onClick={() => handleUpdate(7)}>7</Button>
          </Grid>
          <Grid item xs={3}>
            <Button fullWidth variant="contained" color="info" onClick={() => handleUpdate(8)}>8</Button>
          </Grid>
          <Grid item xs={3}>
            <Button fullWidth variant="contained" color="info" onClick={() => handleUpdate(9)}>9</Button>
          </Grid>
          {/* <Grid item xs={3}>
            <Button fullWidth variant="contained" color="info" >+</Button>
          </Grid> */}
          
          <Grid item xs={3}>
            <Button fullWidth variant="contained" color="info" onClick={() => handleUpdate(0)}>0
              </Button>
          </Grid>
          <Grid item xs={3}>
            <Button fullWidth variant="contained" color="info" onClick={() => handleUpdate(value + 0.5)}>500 g / ML</Button>
          </Grid>
          <Grid item xs={3}>
            <Button fullWidth variant="contained" color="info" onClick={() => handleUpdate(value + 0.25)}>125 g / ML</Button>
          </Grid>
          <Grid item xs={3}>
            <Button fullWidth variant="contained" color="info" onClick={() => handleUpdate(value + 0.1)}>100 g / ML</Button>
          </Grid>
          <Grid item xs={3}>
            <Button fullWidth variant="contained" color="info" onClick={() => handleUpdate(value + 0.05)}>50 g / ML</Button>
          </Grid>
          {/* <Grid item xs={3}>
            <Button fullWidth variant="contained" color="info">=</Button>
          </Grid>
          <Grid item xs={3}>
            <Button fullWidth variant="contained" color="info">-</Button>
          </Grid> */}
          <Grid item xs={6}>
            <Button fullWidth variant="contained" color="error" onClick={() => {
              setValue('')
              onDelete(line)
            }
              }>Supprimer</Button>
          </Grid>
          
        </Grid>
      </Paper>
    </React.Fragment>
  );
}