import axios from "axios";
import config from "../config";
import instance from "utils/AxiosInterceptor";

export const loadCategories = () => {
    return instance.get(`/category/getAll`);
}

export const loadUnits = () => {
  return instance.get(`/data/units`);
}

export const findCategorieById = (id) => {
  return instance.get(`/category/${id}`);
}

export const findSubsByCategId = (id) => {
  return instance.get(`/subcategory/category/${id}`);
}

export const findSubCategorieById = (id) => {
  return instance.get(`/subcategory/${id}`);
}

export const saveCategorie = (request) => {
    return instance.post(`/category/create`, JSON.stringify(request));
}

export const saveSubCategorie = (request) => {
  return instance.post(`/subcategory/create`, JSON.stringify(request));
}

export const updateCategorie = (updated, id) => {
  return instance.put(`/category/update`, JSON.stringify(updated));
}

export const updateSubCategorie = (updated, id) => {
  return instance.put(`/subcategory/${id}`, JSON.stringify(updated));
}


export const uploadImageCategory = async (formData) => {
  const authUser = sessionStorage.getItem("authUser");
  const parsedAuthUser = JSON.parse(authUser);
  let headers = { "Content-type": "multipart/form-data" , "Authorization": `Bearer ${parsedAuthUser.accessToken}`,}
  return await axios.post(`${config.baseURL}/category/upload`, formData, { headers: headers });
}

export const uploadImageSubCategory = (formData) => {
  return instance.post(`/subcategory/upload`, formData);
}


export const setPrincipleImage = (id) => {
  return instance.put(`/category/image/principle/${id}`);
}

export const setPrincipleSubCategImage = (id) => {
  return instance.put(`/subcategory/image/principle/${id}`);
}

export const deleteFile = (fileId) => {
  return instance.delete(`/category/delete/${fileId}`);
}

export const deleteSubCategoryImage = (fileId) => {
  return instance.delete(`/subcategory/delete/${fileId}`);
}
