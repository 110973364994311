import createSagaMiddleware from "@redux-saga/core";
import categorySlice from "./sagas/categorySlice";
import { rootReducer, rootSaga } from "saga-slice";
import { configureStore } from "@reduxjs/toolkit";
import customizationReducer from "./sagas/customizationReducer";
import subCategorySlice from "sagas/subCategorySlice";
import productSlice from "sagas/productSlice";
import dataSlice from "sagas/dataSlice";
import storeSlice from "sagas/storeSlice";
import userSlice, { userActions } from "sagas/userSlice";
import orderSlice from "sagas/orderSlice";

const modules = [categorySlice,subCategorySlice, productSlice, dataSlice, storeSlice, userSlice, orderSlice];

const sagaMiddleware = createSagaMiddleware();

const appReducer = rootReducer(modules,{
    customization: customizationReducer,
});

const store = configureStore({
    reducer:appReducer,
    middleware: [sagaMiddleware]
});

sagaMiddleware.run(rootSaga(modules));

export default store;
