import axios from "axios";
import config from "config";
import instance from "utils/AxiosInterceptor";

export const saveStore = (request) => {
    return axios.post(`${config.baseURL}/store/create`, JSON.stringify(request), {
        headers: {
          "Content-type": "application/json"
        },
      });
}

export const updateStore = (updated) => {
    return axios.put(`${config.baseURL}/store/update`, JSON.stringify(updated), {
        headers: {
          "Content-type": "application/json"
        },
      });
}