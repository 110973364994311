import { call, put } from "redux-saga/effects";

const { createModule } = require("saga-slice");
const { updateOrder, createOrder, findOrderById, loadOrders, search, getOrderNumber, find, report, printReceipt, printReceiptIntern } = require("services/OrderService");

const orderSlice = createModule({
    name:"sale",
    initialState:{
        orders:[],
        isFetchingO:false,
        error:null,
        total:0
    },
    reducers: {
        create: (state) => {
            state.isFetchingO = true;
        },
        list: (state) => {
            state.isFetchingO = true;
        },
        search: (state) => {
            state.isFetchingO = true;
        },
        update: (state) => {
            state.isFetchingO = true;
        },
        find: (state) => {
            state.isFetchingO = true;
        },
        getOrderNumber: (state) => {
            state.isFetchingO = true;
        },
        report: (state) => {
            state.isFetchingO = true;
        },
        printReceipt: (state) => {
            state.isFetchingO = true;
        },
        printReceiptIntern: (state) => {
            state.isFetchingO = true;
        },
        fetchSuccess: (state, payload) => {
            state.orders = payload.body.content;
            state.total = payload.body.totalElements;
        },
        findBySuccess: (state, payload) => {
            state.current = payload;
        },
        finishFetching: (state) => {
            state.isFetchingO = false;
        },
        fetchError: (state) => {
            state.error = "An error occured";
        },
    },
    sagas: (A) =>({
        *[A.list]({payload}) {
            try{
                console.log(payload)
                const {data} = yield loadOrders(payload.page, payload.size);
                yield put(A.finishFetching());
                yield put(A.fetchSuccess(data));
            }catch(e){
                console.log(e)
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }   
        },
        *[A.getOrderNumber]({payload}) {
            try{
                console.log(payload)
                const {data} = yield getOrderNumber();
                yield put(A.finishFetching());
                yield call(payload.onSuccess,data?.body);
            }catch(e){
                console.log(e)
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }   
        },
        *[A.report]({payload}) {
            try{
                console.log(payload)
                const {data} = yield report(payload.query);
                yield put(A.finishFetching());
                yield call(payload.onSuccess,data?.body);
            }catch(e){
                console.log(e)
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }   
        },
        *[A.search]({ payload }) {
            try {
                const { data } = yield search(payload);
                yield put(A.fetchSuccess(data));
                yield put(A.finishFetching());
            } catch (e) {
                console.log(e);
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },
        *[A.create]({payload}){
            try{
                console.log("create payload", payload);
                const {data} = yield createOrder(payload.data);
                yield put(A.finishFetching());
                yield call(payload.onSuccess, data?.body);
            }catch(e){
                console.log(e)
                yield put(A.finishFetching());
                yield put(A.fetchError());
                yield call(payload.onError, e);
            }
        },
        *[A.update]({payload}){
            try{
                console.log("update payload", payload);
                const { data } = yield updateOrder(payload.data);
                yield put(A.finishFetching());
                yield call(payload.onSuccess, data?.body);
            }catch(e){
                yield put(A.finishFetching());
                yield put(A.fetchError());
                yield call(payload.onError, e);
            }
        },
        *[A.find]({ payload }) {
            try {
                const { data } = yield find(payload.id);
                yield put(A.finishFetching());
                yield call(payload.onSuccess, data.body);
            } catch (e) {
                console.log(e);
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },
        *[A.printReceipt]({ payload }) {
            try {
                const {data} = yield printReceipt(payload.id);
                yield put(A.finishFetching());
                yield call(payload.onSuccess, data);
            } catch (e) {
                console.log(e);
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },
        *[A.printReceiptIntern]({ payload }) {
            try {
                const {data} = yield printReceiptIntern(payload.id);
                yield put(A.finishFetching());
                yield call(payload.onSuccess, data);
            } catch (e) {
                console.log(e);
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },

    })
})

export default orderSlice;
export const orderActions = orderSlice.actions;