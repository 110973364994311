import moment from 'moment-timezone';


export function formatDate(date,fmtIn,fmtOut){
    const convertedDate = moment.utc(date, fmtIn).tz("Africa/Casablanca").format(fmtOut);
    return convertedDate;
}

export function getStoreType(){
    const authUser = sessionStorage.getItem("authUser");
    const parsedAuthUser = JSON.parse(authUser);

    return parsedAuthUser?.type;
}

export const getChipColor = (status) => {
    if (status === 'PAYE') {
        return '#48a307';
    } else if (status === 'PAYE_PARTIEL') {
        return '#dc7c0c';
    } else if (status === 'UNPAYE') {
        return '#ef5350';
    }else if (status === 'EMPORTER') {
        return '#48a307';
    }else if (status === 'SURPLACE') {
        return '#ef5350';
    }
}

