import { call, put } from "redux-saga/effects";
import { createModule } from "saga-slice";
import { login } from "../services/userService";
import { DASHBOARD_PAGE, LOGIN_PAGE } from "routes/constants";

const userSlice = createModule({
  name: "user",
  initialState: {
    token: null,
    loading: false,
    error: null,
  },
  reducers: {
    login: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, payload) => {
      state.token = payload.accessToken;
      state.loading = false;
    },
    finishFetching: (state) => {
      state.loading = false;
    },
    logout: (state) => {
      
    },
    fetchError: (state) => {
      state.loading = false;
      state.error = "An error occured";
    },
  },
  sagas: (A) => ({
    *[A.login]({ payload: { user, onSuccess }}) {
      try {
        const { data } = yield login(user);
        sessionStorage.setItem("authUser", JSON.stringify(data));
        yield put(A.loginSuccess(data));
        yield call(onSuccess,data);
        yield put(A.finishFetching());
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.logout]({ payload: { history } }) {
      try {
        sessionStorage.removeItem("authUser");
        sessionStorage.removeItem("roles");
        sessionStorage.clear();
        history(LOGIN_PAGE);
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    
  }),
});

export default userSlice;
export const userActions = userSlice.actions;