import { Backdrop, Button, Chip, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import config from "config";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { ORDER_EDIT } from "routes/constants";
import { categoryActions } from "sagas/categorySlice";
import { orderActions } from "sagas/orderSlice";
import { productActions } from "sagas/productSlice";
import CustomNoRowsOverlay from "ui-component/CustomNoRowsOverlay";
import StyledTable from "ui-component/StyledDataGrid";
import { gridSpacing } from "utils/constant";
import { getChipColor } from "utils/helpers";
import EarningCard from "views/dashboard/Default/EarningCard";
import TotalIncomeDarkCard from "views/dashboard/Default/TotalIncomeDarkCard";
import TotalOrderLineChartCard from "views/dashboard/Default/TotalOrderLineChartCard";

const SaleList = () => {

    const { t } = useTranslation('translation');
    const { status } = useSelector((state) => state.data);
    const { isFetchingO, orders, total } = useSelector((state) => state.sale);
    const [isFetching, setFetching] = useState(true);
    const [page, setPage] = useState(0);
    const [report, setReport] = useState({totalEarning: 0, totalEarning: 0, totalProfit: 0});


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    useEffect(() => {

        if (sessionStorage.getItem("authUser")) {
            const user = JSON.parse(sessionStorage.getItem("authUser"));
            setUser(user);
        }
        let query = {
            criteria: { dateDu: null, dateAu: null },
            page: page,
            size: 10,
        };

        let payload = {
            query: {
                dateDu: null,
                dateAu: null,
            },
            onSuccess: (data) => {
                setReport(data);
                setFetching(false);
            }
        };
        setTimeout(() => {
            dispatch(orderActions.search(query));
            dispatch(orderActions.report(payload));
            dispatch(categoryActions.fetchCategories({ page: 0, size: 10000 }));

        }, [1500]);
    }, [page])

    

    const printReceipt = (id) => {
        setFetching(true)
        let payload = {
            id: id,
            onSuccess: (response) => {
                setFetching(false)
                const pdfWindow = window.open();
                pdfWindow.document.write(
                    "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                    encodeURI(response) +
                    "'></iframe>"
                );
            }
        }

        dispatch(orderActions.printReceipt(payload))
    }


    const columns = user?.store?.type === "RESTAURANT" ? [
        { field: 'code', headerName: t('text.orderNum'), flex: 0.5 },
        {
            field: 'createdDate',
            headerName: t('text.orderDate'),
            flex: 1,
        },
        {
            field: 'total',
            headerName: `${t('text.total')} (${t('text.currency')})`,
            flex: 1,
        },
        {
            field: 'payedAmount',
            headerName: `${t('text.payedAmount')} (${t('text.currency')})`,
            flex: 1,
        },
        {
            field: 'deliveryType',
            headerName: t('sales.deliveryType'),
            flex: 1,
            renderCell: (params) => {
                return <Chip title={params.row.deliveryType} label={params.row.deliveryType}
                    sx={{
                        boxShadow: 2, fontSize: config.chipFontSize,
                        color: '#fff',
                        backgroundColor: getChipColor(params.row.deliveryType)
                    }}
                    variant="contained" />
            }
        },
        {
            field: 'status',
            headerName: t('text.status'),
            flex: 1,
            renderCell: (params) => {
                return <Chip title={status.find(s => s.code === params.row.status)?.descriptionAr} label={status.find(s => s.code === params.row.status)?.descriptionAr}
                    sx={{
                        boxShadow: 2, fontSize: config.chipFontSize,
                        color: '#fff',
                        backgroundColor: getChipColor(params.row.status)
                    }}
                    variant="contained" />
            }
        }, 
        {
            field: "actions",
            headerName: t('text.actions'),
            headerAlign: 'center',
            flex: 1,
            renderCell: (cellValues) => {
                return (<>
                    <Button variant="contained" onClick={() => navigate(ORDER_EDIT.replace(":id", cellValues.row.id))} size="small"  >{t('actions.voir')}</Button>
                    <Button variant="contained" onClick={() => printReceipt(cellValues.row.id)} sx={{mx:1}} size="small" >{t('actions.printReceipt')}</Button>
                    </>
                );
            }
        }  
    ]: [
        { field: 'code', headerName: t('text.orderNum'), flex: 0.5 },
        {
            field: 'createdDate',
            headerName: t('text.orderDate'),
            flex: 1,
        },
        {
            field: 'total',
            headerName: `${t('text.total')} (${t('text.currency')})`,
            flex: 1,
        },
        {
            field: 'payedAmount',
            headerName: `${t('text.payedAmount')} (${t('text.currency')})`,
            flex: 1,
        },
        {
            field: 'status',
            headerName: t('text.status'),
            flex: 1,
            renderCell: (params) => {
                return <Chip title={status.find(s => s.code === params.row.status)?.descriptionAr} label={status.find(s => s.code === params.row.status)?.descriptionAr}
                    sx={{
                        boxShadow: 2, fontSize: config.chipFontSize,
                        color: '#fff',
                        backgroundColor: getChipColor(params.row.status)
                    }}
                    variant="contained" />
            }
        },
        {
            field: "actions",
            headerName: t('text.actions'),
            headerAlign: 'center',
            flex: 1,
            renderCell: (cellValues) => {
                return (<>
                    <Button variant="contained" onClick={() => navigate(ORDER_EDIT.replace(":id", cellValues.row.id))} size="small"  >{t('actions.voir')}</Button>
                    <Button variant="contained" onClick={() => printReceipt(cellValues.row.id)} sx={{mx:1}} size="small" >{t('actions.printReceipt')}</Button>
                    </>
                );
            }
        }  
    ];

    const hasRoleAdmin = () => {
        const roles = sessionStorage.getItem("roles");
        console.log(roles)
        const found = ["ROLE_ADMIN"]?.some(r => roles?.indexOf(r) >= 0);
        return found;
    }

    return (
        <React.Fragment>
            {(isFetching || isFetchingO) &&
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isFetching || isFetchingO}>
                    <CircularProgress color="inherit" />
                </Backdrop>}
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <Paper elevation={0} sx={{ p: 1, backgroundColor: "#e0e0e0" }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', py: 2, px: 2 }}>
                            <Typography variant="h1" >{t('text.orderList')} </Typography>
                            <Box  sx={{ flex: '1 2 auto' }} />
                            <Button variant="contained" onClick={() => navigate("/sales/new")} fu sx={{ color: 'white', backgroundColor: "green", fontSize: 18 }} >{t('actions.newOrder')}</Button>
                        </Box>
                    </Paper>
                </Grid>
                {hasRoleAdmin() && <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            <EarningCard total={report?.totalEarning > 0 ? report?.totalEarning : 0} />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            <TotalOrderLineChartCard total={report?.totalOrders > 0 ? report?.totalOrders : 0} />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            <TotalIncomeDarkCard total={report?.totalProfit > 0 ? report?.totalProfit : 0} />
                        </Grid>
                    </Grid>
                </Grid>}

                <Grid item xs={12} md={12} sm={12}>
                    <Box sx={{ width: '100%', }}>
                        <StyledTable
                            rows={orders ?? []}
                            rowCount={total}
                            pagination
                            page={page}
                            paginationMode="server"
                            pageSize={10}
                            onPageChange={(newPage) => setPage(newPage)}
                            rowsPerPageOptions={[10]}
                            columns={columns}
                            disableSelectionOnClick
                            getRowId={(row) => row.id}
                            components={{
                                NoRowsOverlay : CustomNoRowsOverlay
                              }}/>
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default SaleList;