export const PRODUCT_PAGE = "/base/product";
export const CATEGORY_PAGE = "/base/category";
export const DASHBOARD_PAGE = "/dashboard";
export const LOGIN_PAGE = "/login";
export const REGISTER_PAGE = "/register"
export const ERROR_PAGE_404 = "";
export const ERROR_PAGE_500 = "";
export const ERROR_PAGE_OTHER = "";
export const LOGOUT_PAGE = "/logout";
export const SALES_PAGE = "/sales";
export const ORDER_EDIT = "/sales/edit/:id";
export const ORDER_NEW = "/sales/new";


