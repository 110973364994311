import axios from "axios";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import config from "../config";
import { ERROR_PAGE_404, ERROR_PAGE_500, ERROR_PAGE_OTHER } from "routes/constants";


export const isTokenExpired = (parsedToken) => {
  return Boolean(
    parsedToken?.exp && moment.unix(parsedToken.exp).isBefore(moment())
  );
};
const instance = axios.create({
  baseURL: config.baseURL,
});


const AxiosInterceptor = ({ children }) => {
  const navigate = useNavigate();

  const [isAppReady, setIsAppReady] = React.useState(false);

  let token = null;

  const authUser = sessionStorage.getItem("authUser");

  if (authUser) {
    try {
      console.log("Retrieved authUser from sessionStorage:", authUser);

      const parsedAuthUser = JSON.parse(authUser);
      if (parsedAuthUser && typeof parsedAuthUser === 'object' && 'accessToken' in parsedAuthUser) {
        token = parsedAuthUser.accessToken;
      } else {
        console.error("Parsed authUser does not contain accessToken");
      }
    } catch (error) {
      console.error("Error parsing authUser from sessionStorage", error);
    }
  } else {
    console.error("authUser not found in sessionStorage");
  }



  const getErrorStatusAndMessage = (error) => {
    const errorStatus = error?.response?.data?.status ?? error?.response?.status;
    const errorMessage = error?.response?.data?.message;
    return [errorStatus, errorMessage];
  };

  const handleError = (errorStatus, errorMessage) => {
    if (Boolean(errorStatus)) {
      switch (String(errorStatus)) {
        case "404":
          navigate(ERROR_PAGE_404);
          break;
        case "500":
          navigate(ERROR_PAGE_500);
          break;
        default:
          break;
      }
    } else {
      navigate(ERROR_PAGE_OTHER);
    }
  };

  const configuration = (config) => {
    if (Boolean(token) && !isTokenExpired(jwtDecode(token))) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }
    }
    return config;
  };

  React.useEffect(() => {
    instance.interceptors.request.use((config) => configuration(config));
  }, []);

  React.useEffect(() => {
    const responseInterceptor = instance.interceptors.response.use(
      (response) => response,
      (error) => {
        const [errorStatus, errorMessage] = getErrorStatusAndMessage(error);
        handleError(errorStatus, errorMessage);
        return Promise.reject(error);
      }
    );
    setIsAppReady(true);
    return () => {
      instance.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  //return isAppReady ? children : <Backdrop open={true} />;
  return children;
};

export { AxiosInterceptor };

export default instance;
