import { call, put } from "redux-saga/effects";
import { createModule } from "saga-slice";
import { loadData } from "../services/dataService";

const dataSlice = createModule({
    name: "data",
    initialState: {
        units: [],
        status:[],
        isFetching: false,
        error: null
    },
    reducers: {
        loadData: (state) => {
            state.isFetching = true;
        },
        fetchedData: (state, payload) => {
            state.units = payload.body.units;
            state.status = payload.body.status;
        },
        finishFetching: (state) => {
            state.isFetching = false;
        },
        fetchError: (state) => {
            state.isFetching = false;
            state.error = "An error occured";
        },
    },
    sagas: (A) => ({
        *[A.loadData]() {
            try {
                const { data } = yield loadData();
                yield put(A.finishFetching());
                yield put(A.fetchedData(data));
            } catch (e) {
                console.log(e);
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },
    })
})

export default dataSlice;
export const dataActions = dataSlice.actions;
