import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Box, Button, ButtonBase, Card, CardActions, CardContent, CardMedia, Divider, Grid, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { productActions } from "sagas/productSlice";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function ProductList({ categoryId, onSelect, disabled }) {

    const { products } = useSelector((state) => state.product);
    const { units } = useSelector((state) => state.data);
    const [page, setPage] = useState(0)
    const { t } = useTranslation('translation');

    const dispatch = useDispatch();

    useEffect(() => {
        if (categoryId) {
            dispatch(productActions.search({ criteria: { categId: categoryId }, page: page, size: 1000000 }));
        } else {
            dispatch(productActions.search({ criteria: {}, page: 0, size: 1000000 }));
        }


    }, [page, categoryId])

    useEffect(() => {
    }, [products])

    const getUnit = (code) => {
        return units.find(u => u.code === code)?.descriptionAr
    }

    return (
        <Box style={{ maxHeight: '80vh', overflow: 'auto' }}>

            <Grid container spacing={{ xs: 1, md: 1, }} columns={{ xs: 3, sm: 8, md: 12 }} >
                {products.map((item, index) => (
                    <Grid item xs={2} sm={2} md={2} key={index} >
                        <Card sx={{ my: 0.2, boxShadow: 3, }} >
                            <ButtonBase disabled={disabled} onClick={() => onSelect(item, 1)}>
                                <CardContent>
                                    <Typography variant="h6" color="black">
                                        {item.label}
                                    </Typography>
                                    <CardMedia
                                        className={styles.Media}
                                        component="img"
                                        image={item.photo?.path} />

                                    <Typography variant="h6" >
                                        {`${item.salePrice} ${t('text.currency')} / ${getUnit(item.unitOfMeasure)}`}
                                    </Typography>
                                </CardContent>
                            </ButtonBase>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>

    )
}

const styles = theme => ({
    Card: {
        width: 150,
        margin: 'auto'
    },
    Media: {
        height: 120,
        width: '100%'
    }
});