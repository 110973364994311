import { call, put } from "redux-saga/effects";
import { createModule } from "saga-slice";
import { deleteFile, setPrincipleImage, uploadImageCategory, findCategorieById, loadCategories, saveCategorie, updateCategorie, loadUnits } from "services/categoryService";

const categorySlice = createModule({
    name:"category",
    initialState:{
        categories:[],
        units:[],
        current:{id:0, code:'', description:''},
        isFetching:false,
        error:null
    },
    reducers:{
        fetchCategories: (state) => {
            state.isFetching = true;
        },
        finishFetching: (state) => {
            state.isFetching = false;
        },
        fetchSuccess: (state, payload) => {
            state.categories = payload;
        },
        fetchError: (state) => {
            state.error = "An error occured";
        },
        fetchUnits: (state) => {
            state.isFetching = true;
        },
        fetchUnitsSuccess: (state, payload) => {
            state.units = payload;
        },
        findOneSuccess: (state, payload) => {
            state.current = payload;
        },
        findById: (state) => {
            state.isFetching = true;
        },
        addCategory: (state) =>{
            state.isFetching = true;
        },
        deleteCategory: (state) =>{
            state.isFetching = true;
        },
        editCategory: (state) =>{
            state.isFetching = true;
        },
        uploadFile: (state) => {
            state.isFetching = true;
        },
        choosePrinciple : (state) => {
            state.isFetching = true;
        },
        removeImage: (state) => {
            state.isFetching = true;
        },
        clearCurrent:(state) => {
            state.current = {id:0, code:'', description:''}
        }
    },

    sagas: (A) =>({
        *[A.fetchCategories]({payload}) {
            try{
                const {data} = yield loadCategories();
                yield put(A.finishFetching());
                yield put(A.fetchSuccess(data.body));
            }catch(e){
                console.log(e)
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }   
        },
        *[A.fetchUnits]() {
            try{
                const {data} = yield loadUnits();
                yield put(A.finishFetching());
                yield put(A.fetchUnitsSuccess(data));
            }catch(e){
                console.log(e)
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }   
        },
        *[A.findById]({payload}) {
            try {
                const {data} = yield findCategorieById(payload.id);
                yield put(A.finishFetching());
                yield call(payload.onSuccess, data.body);
            } catch (e) {
                console.log(e);
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }

        },
        *[A.addCategory]({payload}){
            try{
                const {data} =  yield saveCategorie(payload.data);
                yield put(A.finishFetching());
                yield put(A.findOneSuccess(data));
                yield call(payload.onSuccess);
            }catch(e){
                console.log(e)
                yield put(A.finishFetching());
                yield put(A.fetchError());
                yield call(payload.onError, e);
            }
        },
        *[A.deleteCategory]({payload}){
            try{
                console.log("deleteCategory payload", payload);
            }catch(e){
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },
        *[A.editCategory]({payload}){
            try{
                const {data} =  yield updateCategorie(payload.data,payload.data.id);
                yield put(A.finishFetching());
                yield put(A.findOneSuccess(data));
                yield call(payload.onSuccess);
            }catch(e){
                yield put(A.finishFetching());
                yield put(A.fetchError());
                yield call(payload.onError, e);
            }
        },
        *[A.uploadFile]({payload}){

            try {
                const { data } = yield uploadImageCategory(payload.formData);
                yield put(A.finishFetching());
                yield call(payload.onSuccess, data?.body);
            } catch (e) {
                console.log(e);
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },
        *[A.choosePrinciple]({payload}){
            try{
                console.log(payload)
                yield setPrincipleImage(payload.id);
                yield put(A.finishFetching());
                yield call(payload.onSuccess);
            }catch(e){
                yield put(A.finishFetching());
                yield put(A.fetchError());
                yield call(payload.onError, e);
            }
        },
        *[A.removeImage]({payload}){
            try{
                yield deleteFile(payload.id);
                yield put(A.finishFetching());
                yield call(payload.onSuccess);
            }catch(e){
                yield put(A.finishFetching());
                yield put(A.fetchError());
                yield call(payload.onError, e);
            }
        }   
    })
})

export default categorySlice;
export const categoryActions = categorySlice.actions;

