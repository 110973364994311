import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, Button, Grid, Typography } from '@mui/material';

// third-party
import Chart from 'react-apexcharts';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SkeletonTotalOrderCard from 'ui-component/cards/Skeleton/EarningCard';

import ChartDataMonth from './chart-data/total-order-month-line-chart';
import ChartDataYear from './chart-data/total-order-year-line-chart';

// assets
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { orderActions } from 'sagas/orderSlice';

const CardWrapper = styled(MainCard)(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    '&>div': {
        position: 'relative',
        zIndex: 5
    },
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: theme.palette.primary[800],
        borderRadius: '50%',
        zIndex: 1,
        top: -85,
        right: -95,
        [theme.breakpoints.down('sm')]: {
            top: -105,
            right: -140
        }
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        width: 210,
        height: 210,
        background: theme.palette.primary[800],
        borderRadius: '50%',
        top: -125,
        right: -15,
        opacity: 0.5,
        [theme.breakpoints.down('sm')]: {
            top: -155,
            right: -70
        }
    }
}));

// ==============================|| DASHBOARD - TOTAL ORDER LINE CHART CARD ||============================== //

const TotalOrderLineChartCard = ({ isLoading, total }) => {
    const theme = useTheme();
    const { t } = useTranslation('translation');
    const [report, setReport] = useState(null);
    const dispatch = useDispatch();
    const [timeValue, setTimeValue] = useState(1);

    const handleChangeTime = (event, newValue) => {
        setTimeValue(newValue);
        if (newValue === 1) {
            let current = new Date()
            let tomorrow = new Date()
            tomorrow.setDate(tomorrow.getDate() + 1);

            let payload = {
                query: {
                    dateDu: current.toISOString().split('T')[0],
                    dateAu: tomorrow.toISOString().split('T')[0],
                },
                onSuccess: (data) => {
                    setReport(data)
                }
            };
            dispatch(orderActions.report(payload));
            
        }else if(newValue === 2){
            let debut = getMonday(new Date());
            let current = new Date()
            current.setDate(current.getDate() + 1);

            let payload = {
                query: {
                    dateDu: debut.toISOString().split('T')[0],
                    dateAu: current.toISOString().split('T')[0],
                },
                onSuccess: (data) => {
                    setReport(data)
                }
            };
            dispatch(orderActions.report(payload));

        }else if(newValue === 3){
            let date = new Date();
            let debut = new Date(date.getFullYear(), date.getMonth(), 1);
            let current = new Date()
            current.setDate(current.getDate() + 1);

            let payload = {
                query: {
                    dateDu: debut.toISOString().split('T')[0],
                    dateAu: current.toISOString().split('T')[0],
                },
                onSuccess: (data) => {
                    setReport(data)
                }
            };
            dispatch(orderActions.report(payload));

        }else if(newValue === 4){
            let date = new Date();
            let debut = new Date(date.getFullYear(), 0, 1);
            let current = new Date()
            current.setDate(current.getDate() + 1);

            let payload = {
                query: {
                    dateDu: debut.toISOString().split('T')[0],
                    dateAu: current.toISOString().split('T')[0],
                },
                onSuccess: (data) => {
                    setReport(data)
                }
            };
            dispatch(orderActions.report(payload));
        }

    };

    function getMonday(d) {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    return (
        <>
            {isLoading ? (
                <SkeletonTotalOrderCard />
            ) : (
                <CardWrapper border={false} content={false}>
                    <Box sx={{ p: 2.25 }}>
                        <Grid container direction="column">
                            <Grid item>
                                <Grid container justifyContent="space-between">
                                    <Grid item>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                ...theme.typography.commonAvatar,
                                                ...theme.typography.largeAvatar,
                                                backgroundColor: theme.palette.primary[800],
                                                color: '#fff',
                                                mt: 1
                                            }}
                                        >
                                            <LocalMallOutlinedIcon fontSize="inherit" />
                                        </Avatar>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            disableElevation
                                            variant={timeValue === 1 ? 'contained' : 'text'}
                                            size="small"
                                            sx={{ color: 'inherit', }}
                                            onClick={(e) => handleChangeTime(e, 1)} >
                                            {t('text.today')}
                                        </Button>
                                        <Button
                                            disableElevation
                                            variant={timeValue === 2 ? 'contained' : 'text'}
                                            size="small"
                                            sx={{ color: 'inherit', }}
                                            onClick={(e) => handleChangeTime(e, 2)}
                                        >
                                            {t('text.week')}
                                        </Button>
                                        <Button
                                            disableElevation
                                            variant={timeValue === 3 ? 'contained' : 'text'}
                                            size="small"
                                            sx={{ color: 'inherit', }}
                                            onClick={(e) => handleChangeTime(e, 3)}
                                        >
                                            {t('text.month')}
                                        </Button>
                                        <Button
                                            disableElevation
                                            variant={timeValue === 4 ? 'contained' : 'text'}
                                            size="small"
                                            sx={{ color: 'inherit' }}
                                            onClick={(e) => handleChangeTime(e, 4)}
                                        >
                                            {t('text.year')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <Typography sx={{ fontSize: '2.5rem', fontWeight: 500, mr: 10,  }}>
                                            {` ${!!report ? report?.totalOrders : total} ${t('text.orders')}`}
                                        </Typography>
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                            <Grid item >
                                <Typography
                                    sx={{
                                        fontSize: '2rem',
                                        fontWeight: 500,
                                        color: theme.palette.primary[200],
                                        mr: 10,
                                    }}
                                >
                                    {t('text.totalOrders')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};

TotalOrderLineChartCard.propTypes = {
    isLoading: PropTypes.bool
};

export default TotalOrderLineChartCard;
