import { call, put } from "redux-saga/effects";
import { createModule } from "saga-slice";
import { findSubCategorieById, saveSubCategorie, updateSubCategorie, deleteSubCategoryImage, setPrincipleSubCategImage, uploadImageSubCategory, findSubsByCategId } from "services/categoryService";

const subCategorySlice = createModule({
    name: "subCategory",
    initialState: {
        subCategories: [],
        isFetching: false,
        error: null
    },
    reducers: {
        finishFetching: (state) => {
            state.isFetching = false;
        },
        fetchSuccess: (state, payload) => {
            state.subCategories = payload.body;
        },
        fetchError: (state) => {
            state.error = "An error occured";
        },
        findById: (state) => {
            state.isFetching = true;
        },
        findSubsByCategId: (state) => {
            state.isFetching = true;
        },
        addSubCategory: (state) => {
            state.isFetching = true;
        },
        deleteSubCategory: (state) => {
            state.isFetching = true;
        },
        editSubCategory: (state) => {
            state.isFetching = true;
        },
        uploadImgSubCateg: (state) => {
            state.isFetching = true;
        },
        choosePrinciple: (state) => {
            state.isFetching = true;
        },
        removeImage: (state) => {
            state.isFetching = true;
        },
        uploadFile: (state) => {
            state.isFetching = true;
        },
    },

    sagas: (A) => ({
        
        *[A.findById]({ payload }) {
            try {
                const { data } = yield findSubCategorieById(payload.id);
                yield put(A.finishFetching());
                yield call(payload.onSuccess(data.body));
            } catch (e) {
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },
        *[A.findSubsByCategId]({ payload }) {
            try {
                console.log(payload)
                const { data } = yield findSubsByCategId(payload.id);
                console.log(data)
                yield put(A.finishFetching());
                yield put(A.fetchSuccess(data));
            } catch (e) {
                console.log(e)
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },
        *[A.addSubCategory]({ payload }) {
            try {
                const { data } = yield saveSubCategorie(payload.data);
                yield put(A.finishFetching());
                yield call(payload.onSuccess(data.body));
            } catch (e) {
                console.log(e)
                yield put(A.finishFetching());
                yield put(A.fetchError());
                yield call(payload.onError(e));
            }
        },
        *[A.deleteSubCategory]({ payload }) {
            try {
                console.log("deleteCategory payload", payload);
            } catch (e) {
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },
        *[A.uploadFile]({payload}){

            try {
                const { data } = yield uploadImageSubCategory(payload.formData);
                yield put(A.finishFetching());
                yield call(payload.onSuccess, data?.body);
            } catch (e) {
                console.log(e);
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },
        *[A.editSubCategory]({ payload }) {
            try {
                const { data } = yield updateSubCategorie(payload.data, payload.data.id);
                yield put(A.finishFetching());
                yield call(payload.onSuccess(data.body));
            } catch (e) {
                yield put(A.finishFetching());
                yield put(A.fetchError());
                yield call(payload.onError, e);
            }
        },

        *[A.choosePrinciple]({ payload }) {
            try {
                console.log(payload)
                yield setPrincipleSubCategImage(payload.id);
                yield call(payload.onSuccess);
            } catch (e) {
                yield put(A.finishFetching());
                yield put(A.fetchError());
                yield call(payload.onError, e);
            }
        },
        *[A.removeImage]({ payload }) {
            try {
                console.log(payload)
                yield deleteSubCategoryImage(payload.id);
                yield call(payload.onSuccess);
            } catch (e) {
                yield put(A.finishFetching());
                yield put(A.fetchError());
                yield call(payload.onError, e);
            }
        },
        *[A.uploadImgSubCateg]({ payload }) {
            try {
                yield uploadImageSubCategory(payload.data);
                yield put(A.finishFetching());
                yield call(payload.onSuccess);
            } catch (e) {
                console.log(e)
                yield put(A.finishFetching());
                yield put(A.fetchError());
                yield call(payload.onError(e));
            }
        },

    })
})

export default subCategorySlice;
export const subCategoryActions = subCategorySlice.actions;

