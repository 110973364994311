import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import { authProtectedRoutes, publicRoutes } from 'routes';

// defaultTheme
import themes from 'themes';
import "./i18n";

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { categoryActions } from 'sagas/categorySlice';
import { useEffect } from 'react';
import { dataActions } from 'sagas/dataSlice';
import { Routes, Route } from "react-router-dom";
import MinimalLayout from 'layout/MinimalLayout';
import MainLayout from 'layout/MainLayout';
import AppRoute from 'routes/route';
import i18n from './i18n';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const dispatch = useDispatch();

    useEffect(() => {
        const dir = i18n.dir(i18n.language);
        document.documentElement.dir = dir;
        dispatch(dataActions.loadData());

     }, [i18n, i18n.language]);

    return (
        <StyledEngineProvider injectFirst>

            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes>
                        {publicRoutes.map((route, idx) => (
                            <Route
                                path={route.path}
                                element={
                                    <MinimalLayout>
                                        {route.component}
                                    </MinimalLayout>
                                }
                                key={idx}
                                exact={true}
                            />
                        ))}

                        {authProtectedRoutes.map((route, idx) => (
                            <Route
                                path={route.path}
                                element={
                                    <AppRoute roles={route.roles}>
                                        <MainLayout>{route.component}</MainLayout>
                                    </AppRoute>}
                                key={idx}
                                exact={true}
                            />
                        ))}
                    </Routes>
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
