import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, InputAdornment, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';


const CustomInputDialog = ({ formik, data, open, close, header, message, cancelOnClick, cancelText, confirmOnClick, confirmText }) => {

    const [reste, setReste] = useState(0);
    const [payed, setPayed] = useState(0);
    const { t } = useTranslation('translation');

    useEffect(() => {
        formik.setFieldValue('payedAmount',formik.values.total);
        setPayed(formik.values.total);
    },[])
    const handleChange = (event) => {
        let res = parseFloat(event.target.value -  formik.values.total).toFixed(2);
        if (event.target.value > formik.values.total) {
            formik.setFieldValue('payedAmount',formik.values.total);
        } else{
            formik.setFieldValue('payedAmount',event.target.value);

        }
        
        setReste(res);
        setPayed(event.target.value)
        
    }

    return <Dialog

        open={open}
        onClose={close}>
        <DialogTitle sx>
            <Typography variant='h2'>{header}</Typography>
        </DialogTitle>
        <DialogContent >
            <DialogContentText >
            <Typography variant='h2'>{message}</Typography>
            </DialogContentText>
            <FormikProvider formik={formik}>


                <TextField
                    autoFocus
                    margin="dense"
                    label={t('text.total')}
                    type="text"
                    {...formik.getFieldProps('total')}
                    disabled
                    fullWidth
                    sx={{fontSize: 24, fontWeight:'bold'}}
                    variant="outlined"
                    InputLabelProps={{style: {fontSize: 28}}} // font size of input label
                    InputProps={{style: {fontSize: 28},
                        startAdornment: <InputAdornment position="start">{t('text.currency')}</InputAdornment>,
                    }}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    label={t('text.payedAmount')}
                    type="number"
                    value={payed}
                    onChange={(event) => handleChange(event)}
                    fullWidth
                    sx={{fontSize: 24, fontWeight:'bold'}}
                    variant="outlined"
                    InputLabelProps={{style: {fontSize: 28}}} // font size of input label
                    InputProps={{style: {fontSize: 28},
                        startAdornment: <InputAdornment position="end">{t('text.currency')}</InputAdornment>,
                    }}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    label={t('text.rendu')}
                    value={reste}
                    disabled
                    type="number"
                    sx={{fontSize: 24, fontWeight:'bold'}}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{style: {fontSize: 28}}} // font size of input label
                    InputProps={{style: {fontSize: 28},
                        startAdornment: <InputAdornment position="end">{t('text.currency')}</InputAdornment>,
                    }}
                />
            </FormikProvider>
        </DialogContent>
        <DialogActions>
           
            <Button autoFocus onClick={confirmOnClick} variant="contained">
                {confirmText}
            </Button>
            <Button onClick={cancelOnClick} variant="contained">
                {cancelText}
            </Button>
        </DialogActions>
    </Dialog>
}
export default CustomInputDialog;